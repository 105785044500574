import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { Card } from '@dev/base-web/dist/view/components/global/card';
import FilterTags from '@dev/base-web/dist/view/components/filter_tags_and_sorting_dropdown/components/filter_tags';
import {
  FilterTag,
  FilterType,
  isDateFilter,
} from '@dev/base-web/dist/view/components/filter_tags_and_sorting_dropdown/components/interfaces';
import FilterPopup, {
  getFilterData,
} from '@dev/base-web/dist/view/components/filter_tags_and_sorting_dropdown/components/filter_popup';
import {
  ComponentType,
  CurrentEventsCardProps,
  EVENT_NAME_FILTER_KEY,
  EVENT_TYPE_FILTER_KEY,
  eventListFilterProps,
  INCLUDE_SUBSEQUENT_EVENTS_FILTER_KEY,
  MANUFACTURING_ENTITY_FILTER_KEY,
} from './interface';
import CardEventsList from './components/events_list';
import { useWindowSize } from '@dev/base-web/dist/view/themes/helpers';
import { useUserConfiguration } from '@dev/base-web/dist/view/components/global/current_user_context';
import { FilterOperation } from '@dev/base-web/dist/model/domain/common/filter_data';
import {
  useGlobalEventTypesSearchState,
  useGlobalBooleanSearchState,
  useGlobalStringSearchState,
  useGlobalEntityIdsSearchState,
} from '@/screens/common/global_config_state';
import _ from 'lodash';
import { useIntl } from 'react-intl';
import { useMemoizedFilters } from '@dev/base-web/dist/view/helpers/filter_hooks';
import { updateGlobalFilters } from './components/filter_update_helper';
import { EventStatus } from '@/screens/current_events/view';
import { Button } from '@dev/base-web/dist/view/components/global/button';
import { useNavigate } from 'react-router';
import { CONFIG as CURRENT_EVENTS_CONFIG } from '../../screens/current_events';
import { checkLocalStorage } from '../tour/Tour';

export const FilterTagsWrapper = styled.div`
  padding: 10px ${({ theme }) => theme.cards.normalWrapper.padding.horizontal}px;
`;

const CURRENT_EVENTS_LIST_FOOTER_TOP_PADDING = 5;
const CURRENT_EVENTS_LIST_FOOTER_BOTTOM_PADDING = 8;
const CURRENT_EVENTS_LIST_LIST_HEIGHT = 342;

// 72px for the card title and 40px for the "show all events" button height. 56px for the filter tags
export const CURRENT_EVENTS_LIST_CARD_HEIGHT =
  72 +
  CURRENT_EVENTS_LIST_LIST_HEIGHT +
  CURRENT_EVENTS_LIST_FOOTER_TOP_PADDING +
  40 +
  CURRENT_EVENTS_LIST_FOOTER_BOTTOM_PADDING;

const Footer = styled.div`
  position: relative;
  bottom: 0;
  width: 100%;
  display: flex;
  padding-top: ${CURRENT_EVENTS_LIST_FOOTER_TOP_PADDING}px;
  padding-bottom: ${CURRENT_EVENTS_LIST_FOOTER_BOTTOM_PADDING}px;
  justify-content: center;
`;

const filterExternalTag = (
  externalFilterTag: FilterTag | undefined,
  updatedFilterTags: readonly FilterTag[]
) => {
  return updatedFilterTags.filter((tag) => {
    if (
      externalFilterTag &&
      externalFilterTag.filterData[0].value &&
      tag.filterData[0].value
    ) {
      return !_.isEqual(
        tag.filterData[0].value,
        externalFilterTag.filterData[0].value
      );
    }
    return true;
  });
};

const EventsListCardWithFilters = ({
  tab = EventStatus.OPEN,
  tourActive,
  onEventSelect,
  selectedEvent,
  activeEvents,
  closedEvents,
  manufacturingEntities,
  manufacturingEntitiesLoading,
  componentType = ComponentType.dashboard,
  getActiveEvents,
  reloadActiveEvents,
  getClosedEventsFirstPage,
  getMoreClosedEvents,
  reloadClosedEvents,
  getAllManufacturingEntities,
  externalFilterTag,
}: CurrentEventsCardProps) => {
  const { height } = useWindowSize();
  const intl = useIntl();
  const navigate = useNavigate();
  const [userConfiguration] = useUserConfiguration();

  const filterProps = useMemo(
    () =>
      eventListFilterProps(
        manufacturingEntities,
        manufacturingEntitiesLoading,
        tab
      ),
    [manufacturingEntities, manufacturingEntitiesLoading, tab]
  );

  const [isLoadingDisabled, setLoadingDisabled] = useState(true);

  const [timespanLocalFilterTag, setTimespanLocalFilterTag] =
    useState<FilterTag>();

  const [eventName, onEventNameChange] = useGlobalStringSearchState({
    key: 'eventName',
  });

  const [eventTypes, setEventTypes] = useGlobalEventTypesSearchState({
    key: 'eventTypes',
  });

  const [entityIds, setEntityIds] = useGlobalEntityIdsSearchState({
    key: 'manufacturingEntityIds',
  });

  const [includeSubsequentEvents, setIncludeSubsequentEvents] =
    useGlobalBooleanSearchState({
      key: INCLUDE_SUBSEQUENT_EVENTS_FILTER_KEY,
    });

  const [previousExternalFilterTag, setPreviousExternalFilterTag] =
    useState<FilterTag>();

  const globalFilterTags: FilterTag[] = useMemo(() => {
    const global: FilterTag[] = [];

    if (eventName) {
      global.push({
        type: FilterType.TEXT,
        icon: 'info',
        label: eventName,
        filterData: [
          {
            key: EVENT_NAME_FILTER_KEY,
            operation: FilterOperation.CT_OPERATOR,
            value: eventName,
          },
        ],
      });
    }

    if (eventTypes !== undefined && eventTypes.length > 0) {
      global.push({
        type: FilterType.EVENT_TYPE,
        filterData: [
          {
            key: EVENT_TYPE_FILTER_KEY,
            operation: FilterOperation.EQ_OPERATOR,
            value: eventTypes,
          },
        ],
      });
    }

    if (
      externalFilterTag &&
      externalFilterTag !== undefined &&
      externalFilterTag.filterData[0].value
    ) {
      const value = externalFilterTag.filterData[0].value;
      if (Array.isArray(value) && value.length > 0) {
        global.push(externalFilterTag);
        setPreviousExternalFilterTag(externalFilterTag);
      }
    } else if (externalFilterTag === undefined && previousExternalFilterTag) {
      // Don't add entity filter if external filter is removed(set dropdown to 'all`)
      setPreviousExternalFilterTag(undefined);
    } else if (
      entityIds.length > 0 &&
      userConfiguration.eventListFilterConfig
    ) {
      global.push({
        type: FilterType.MANUFACTURING_ENTITY,
        icon: 'location',
        filterData: [
          {
            key: MANUFACTURING_ENTITY_FILTER_KEY,
            operation: FilterOperation.EQ_OPERATOR,
            value: entityIds,
          },
        ],
        manufacturingEntities: manufacturingEntities,
      });
    }

    if (includeSubsequentEvents !== undefined && includeSubsequentEvents) {
      global.push({
        type: FilterType.BOOLEAN,
        icon: 'info',
        label: intl.formatMessage({ id: 'include_subsequent_events' }),
        filterData: [
          {
            key: INCLUDE_SUBSEQUENT_EVENTS_FILTER_KEY,
            operation: FilterOperation.EQ_OPERATOR,
            value: includeSubsequentEvents,
          },
        ],
      });
    }

    setLoadingDisabled(false);

    return global;
  }, [
    eventName,
    eventTypes,
    entityIds,
    includeSubsequentEvents,
    manufacturingEntities,
    externalFilterTag,
  ]);

  // the date filters should filter events a bit differently for open and closed events
  // the range end key field depends on it
  const localFilterTags: FilterTag[] = useMemo(() => {
    if (!timespanLocalFilterTag) {
      return [] as FilterTag[];
    } else if (isDateFilter(timespanLocalFilterTag)) {
      return [
        {
          ...timespanLocalFilterTag,
          filterData: [
            timespanLocalFilterTag.filterData[0],
            {
              ...timespanLocalFilterTag.filterData[1],
              key:
                tab === EventStatus.CLOSED ? 'endTimestamp' : 'startTimestamp',
            },
          ],
        },
      ];
    } else {
      return [timespanLocalFilterTag];
    }
  }, [tab, timespanLocalFilterTag]);

  const combinedFilterTags = useMemo(() => {
    return [...globalFilterTags, ...localFilterTags];
  }, [globalFilterTags, localFilterTags]);

  const filtersData = useMemoizedFilters(
    () => getFilterData(combinedFilterTags),
    [combinedFilterTags]
  );

  useEffect(() => {
    if (!manufacturingEntitiesLoading && manufacturingEntities.length === 0) {
      getAllManufacturingEntities();
    }
  }, []);

  function handleEventSelect(eventId: string) {
    if (componentType === ComponentType.dashboard) {
      if (checkLocalStorage('tour-dashboard')) {
        localStorage.setItem('tour-dashboard', '4');
      }
      navigate({
        pathname: `/${CURRENT_EVENTS_CONFIG.url.path}/${eventId}`,
      });
    } else if (onEventSelect) {
      onEventSelect(eventId);
    }
  }

  return (
    <Card
      titleId={
        tab === EventStatus.OPEN ? 'tab_curr_events' : 'tab_closed_events'
      }
      headerContent={
        <FilterPopup
          filters={combinedFilterTags}
          filterProps={filterProps}
          onFiltersChanged={(updatedFilterTags) => {
            updateGlobalFilters(
              filterExternalTag(externalFilterTag, updatedFilterTags),
              onEventNameChange,
              setEventTypes,
              setTimespanLocalFilterTag,
              setEntityIds,
              setIncludeSubsequentEvents,
              eventName,
              eventTypes,
              timespanLocalFilterTag,
              entityIds,
              includeSubsequentEvents
            );
          }}
        />
      }
      margin="none"
    >
      <FilterTagsWrapper>
        <FilterTags
          filterTags={combinedFilterTags}
          tagMaxWidth={250}
          onChange={(updatedFilterTags) => {
            updateGlobalFilters(
              filterExternalTag(externalFilterTag, updatedFilterTags),
              onEventNameChange,
              setEventTypes,
              setTimespanLocalFilterTag,
              setEntityIds,
              setIncludeSubsequentEvents,
              eventName,
              eventTypes,
              timespanLocalFilterTag,
              entityIds,
              includeSubsequentEvents
            );
          }}
        />
      </FilterTagsWrapper>
      <CardEventsList
        tourActive={tourActive}
        height={
          componentType === ComponentType.dashboard
            ? CURRENT_EVENTS_LIST_LIST_HEIGHT
            : height - 335
        }
        tab={tab}
        bottomGradientHeight={64}
        topGradientHeight={12}
        filters={filtersData}
        onEventSelect={handleEventSelect}
        selectedEvent={selectedEvent}
        events={
          tab === EventStatus.OPEN
            ? activeEvents.data.results
            : closedEvents.data.results
        }
        hasMoreResults={
          tab === EventStatus.OPEN
            ? activeEvents.data.hasMoreResults
            : closedEvents.data.hasMoreResults
        }
        loadingInProgress={
          tab === EventStatus.OPEN
            ? activeEvents.meta.loadingInProgress
            : closedEvents.meta.loadingInProgress
        }
        loadError={
          tab === EventStatus.OPEN
            ? activeEvents.meta.error
            : closedEvents.meta.error
        }
        loadEvents={
          tab === EventStatus.OPEN ? getActiveEvents : getClosedEventsFirstPage
        }
        reloadEvents={
          tab === EventStatus.OPEN ? reloadActiveEvents : reloadClosedEvents
        }
        loadMoreEvents={
          tab === EventStatus.CLOSED ? getMoreClosedEvents : undefined
        }
        isLoadingDisabled={isLoadingDisabled}
      />
      {componentType === ComponentType.dashboard && (
        <Footer>
          <Button
            type="tertiary"
            label="show_all_event"
            onClick={() =>
              navigate({
                pathname: `/${CURRENT_EVENTS_CONFIG.url.path}`,
              })
            }
          />
        </Footer>
      )}
    </Card>
  );
};

export default EventsListCardWithFilters;
