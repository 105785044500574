import { EventType } from '@dev/base-web/dist/model/domain/event/event_type';
import {
  EVENT_NAME_FILTER_KEY,
  MANUFACTURING_ENTITY_FILTER_KEY,
  INCLUDE_SUBSEQUENT_EVENTS_FILTER_KEY,
  EVENT_TYPE_FILTER_KEY,
  TIMESPAN_FILTER_KEY,
} from '../interface';
import _ from 'lodash';
import { FilterTag } from '@dev/base-web/dist/view/components/filter_tags_and_sorting_dropdown/components/interfaces';

export const areArraysEqual = <T>(
  array1: readonly T[],
  array2: readonly T[]
) => {
  const genericSort = (a: T, b: T) => (a > b ? 1 : a < b ? -1 : 0);
  const array2Sorted = array2.slice().sort(genericSort);
  return (
    array1.length === array2.length &&
    array1
      .slice()
      .sort(genericSort)
      .every(function (value, index) {
        return value === array2Sorted[index];
      })
  );
};

export const updateGlobalFilters = (
  changedFilterTags: readonly FilterTag[],
  onEventNameChange: (value: string | undefined) => void,
  setEventTypes: (values: readonly EventType[]) => void,
  setTimespanLocalFilterTag: React.Dispatch<
    React.SetStateAction<FilterTag | undefined>
  >,
  setEntityIds: (values: readonly string[] | undefined) => void,
  setIncludeSubsequentEvents: (value: boolean | undefined) => void,
  eventName?: string,
  eventTypes?: readonly EventType[],
  timespanLocalFilterTag?: FilterTag,
  entityIds?: readonly string[],
  includeSubsequentEvents?: boolean
) => {
  const keySet = new Set<string>();
  changedFilterTags.forEach((filterTag: FilterTag) => {
    const filter = filterTag.filterData[0];

    keySet.add(filter.key);

    if (filter.key === EVENT_NAME_FILTER_KEY && filter.value !== eventName) {
      onEventNameChange(filter.value as string);
    } else if (
      filter.key === MANUFACTURING_ENTITY_FILTER_KEY &&
      (!entityIds ||
        (entityIds && !areArraysEqual(filter.value as string[], entityIds)))
    ) {
      setEntityIds(
        _.isArray(filter.value)
          ? (filter.value as string[])
          : [filter.value as string]
      );
    } else if (filter.key === INCLUDE_SUBSEQUENT_EVENTS_FILTER_KEY) {
      setIncludeSubsequentEvents(filter.value as boolean);
    } else if (
      filter.key === EVENT_TYPE_FILTER_KEY &&
      (!eventTypes ||
        (eventTypes &&
          !areArraysEqual(filter.value as EventType[], eventTypes)))
    ) {
      setEventTypes(filter.value as readonly EventType[]);
    } else if (
      filter.key === TIMESPAN_FILTER_KEY &&
      !_.isEqual(filterTag, timespanLocalFilterTag)
    ) {
      setTimespanLocalFilterTag(filterTag);
    }
  });

  if (!keySet.has(TIMESPAN_FILTER_KEY) && timespanLocalFilterTag) {
    setTimespanLocalFilterTag(undefined);
  }

  if (!keySet.has(EVENT_NAME_FILTER_KEY) && eventName) {
    onEventNameChange('');
  }

  if (
    !keySet.has(INCLUDE_SUBSEQUENT_EVENTS_FILTER_KEY) &&
    includeSubsequentEvents !== false
  ) {
    setIncludeSubsequentEvents(false);
  }

  if (
    !keySet.has(MANUFACTURING_ENTITY_FILTER_KEY) &&
    entityIds &&
    entityIds.length !== 0
  ) {
    setEntityIds([]);
  }

  if (
    !keySet.has(EVENT_TYPE_FILTER_KEY) &&
    eventTypes &&
    eventTypes.length !== 0
  ) {
    setEventTypes([]);
  }
};
