import React, { useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';
import { FilterData } from '@dev/base-web/dist/model/domain/common/filter_data';
import {
  EventInterface,
  EventType,
} from '@dev/base-web/dist/model/domain/event/event_type';
import EmptyOrLoadingView from '@dev/base-web/dist/view/components/global/no_data';
import DetailLoader from '@dev/base-web/dist/view/components/global/detail_loader';
import CurrentEventItem from './events_list_item';
import { CardInfiniteScroll } from '@dev/base-web/dist/view/components/global/card';
import { LoadType } from '@dev/base-web/dist/model/redux/events/interface';
import { useNavigate } from 'react-router';
import { dummyItem } from './dummy_item';
import { EventStatus } from '@/screens/current_events/view';

export interface EventsListProps {
  readonly height: number;
  readonly className?: string;
  readonly selectedEvent?: EventInterface;
  readonly onEventSelect: (id: string) => void;
  readonly filters?: readonly FilterData[];
  readonly events: readonly EventInterface[];
  readonly hasMoreResults: boolean;
  readonly loadingInProgress: LoadType | undefined;
  readonly loadError: unknown;
  readonly topGradientHeight?: number;
  readonly bottomGradientHeight?: number;
  readonly loadEvents: (filters: readonly FilterData[]) => void;
  readonly reloadEvents: (filters: readonly FilterData[]) => void;
  readonly loadMoreEvents?: (filters: readonly FilterData[]) => void;
  readonly tourActive?: boolean;
  readonly isLoadingDisabled?: boolean;
  readonly tab?: EventStatus;
}

const EmptyViewWrapper = styled.div<{ height: number }>`
  height: ${({ height }) => height}px;
  display: flex;
  align-items: center;
`;

const RelativeWrapper = styled.div`
  position: relative;
`;

const RefreshingOverlay = styled.div`
  background-color: rgba(255, 255, 255, 0.5);
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;
`;

const EMPTY: readonly FilterData[] = [];
const NO_ACTION: (filters: readonly FilterData[]) => void = () => {};

const useTimer = (callback: () => void) => {
  const timer = useRef<number | undefined>();

  const setTimer = () => {
    clearTimer();

    timer.current = window.setTimeout(() => {
      callback();
      setTimer();
    }, 5000);
  };

  const clearTimer = () => {
    if (timer.current) {
      clearInterval(timer.current);
      timer.current = undefined;
    }
  };

  return [setTimer, clearTimer];
};

const CardEventsList: React.FC<EventsListProps> = ({
  height,
  tab,
  filters = EMPTY,
  events,
  hasMoreResults,
  loadingInProgress,
  loadError,
  loadEvents,
  reloadEvents,
  loadMoreEvents = NO_ACTION,
  onEventSelect,
  selectedEvent,
  tourActive,
  isLoadingDisabled,
  ...props
}) => {
  const intl = useIntl();
  const navigate = useNavigate();
  const [scheduleNextPage, setScheduleNextPage] = useState(false);

  const [setTimer, clearTimer] = useTimer(() => {
    reloadEvents(filters);
  });

  useEffect(() => {
    if (isLoadingDisabled) return;

    if (events.length > 0) {
      reloadEvents(filters);
    } else {
      loadEvents(filters);
    }

    setTimer();

    return clearTimer;
  }, [filters, intl.locale, isLoadingDisabled, tab]);

  useEffect(() => {
    if (
      loadingInProgress === undefined &&
      scheduleNextPage &&
      !isLoadingDisabled
    ) {
      setScheduleNextPage(false);
      loadMoreEvents(filters);
      setTimer();
    }
  }, [loadingInProgress, scheduleNextPage, isLoadingDisabled]);

  if (loadError || events.length === 0) {
    return (
      <EmptyViewWrapper height={height}>
        <EmptyOrLoadingView
          loadingInProgress={loadingInProgress !== undefined}
          hasError={!!loadError}
          hasResults={events.length > 0}
          noDataAvailableText={'no_active_events'}
        />
      </EmptyViewWrapper>
    );
  }

  return (
    <RelativeWrapper>
      {loadingInProgress === 'reload' && (
        <RefreshingOverlay>
          <DetailLoader />
        </RefreshingOverlay>
      )}
      <CardInfiniteScroll
        dataLength={events.length}
        next={() => {
          if (loadingInProgress === 'reload') {
            setScheduleNextPage(true);
          } else {
            loadMoreEvents(filters);
            setTimer();
          }
        }}
        hasMore={hasMoreResults}
        height={height}
        onScroll={() => setTimer()}
        {...props}
      >
        {events.map((item) => {
          return (
            <CurrentEventItem
              unselectable={
                tourActive && item.type === EventType.RECOMMENDATION
              }
              item={item}
              key={item.id}
              onClick={onEventSelect}
              selected={selectedEvent && item.id === selectedEvent.id}
            />
          );
        })}
        {tourActive && (
          <CurrentEventItem
            item={dummyItem}
            key={dummyItem.id}
            onClick={() => navigate('/currentEvents/dum')}
            selected={false}
          />
        )}
      </CardInfiniteScroll>
    </RelativeWrapper>
  );
};

export default CardEventsList;
