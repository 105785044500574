import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
//import CurrentEventsList from './components/current_events_list';
import { CONFIG as USER_ADMIN_CONFIG } from '../user_list/interface';
import { CONFIG as EVENTS_CONFIG } from '../events/interfaces';
import { CONFIG as ACTIONS_CONFIG } from '../instructions';
import { CONFIG as REPORTS_CONFIG } from '../reports/overview';
import { DashboardButtonProps } from '@dev/base-web/dist/view/components/dashboard/dashboard_button';
import DashBoardGrid from '@dev/base-web/dist/view/components/dashboard/dashboard_grid';
import {
  ScreenContent,
  ScreenRoot,
} from '@dev/base-web/dist/view/components/global/styled_components';
import {
  CardColumn,
  CardRow,
  FlexibleCardColumn,
  MultiCardLayout,
} from '@dev/base-web/dist/view/components/global/card';
import Tour from '../../components/tour/Tour';
import DowntimeCard from './components/downtime_card';
import { OPEN_DOWNTIME_REPORT_LIST_ITEM_HEIGHT } from './components/open_downtime_reports_list_card/components/open_downtime_reports_list_item';
import useAuthorizationCheck from '@dev/base-web/dist/view/components/global/user_authorization_hook';
import SupportDashboard from './components/support_dashboard';
import {
  ApplicationCategory,
  UserPrivilegePermission,
} from '@dev/base-web/dist/model/domain/user_privilege/user_privilege';
import IntervalKPIs from './components/interval_kpis';
import ManufacturingEntity from '@dev/base-web/dist/model/domain/manufacturing_entity/manufacturing_entity';
import { DropdownProps } from 'semantic-ui-react';
import { IntervalType } from '@dev/base-web/dist/model/domain/interval_types';
import { getManufacturingEntityTitle } from '@dev/base-web/dist/view/helpers/common';
import { StyledDropDown } from '@dev/base-web/dist/view/components/inputs/styled_components';
import { useIntl } from 'react-intl';
import { DashboardPropsInterface } from './interface';
import ChartOptionsPopup from './components/downtime_card/components/chart_options';
import OpenDowntimeReportsListCard from './components/open_downtime_reports_list_card/index';
import { groupKpisByMainEntity } from '@/model/domain/kpis/factory_area_child_result.ts';
import Header from '@dev/base-web/dist/view/components/global/header_view';
import { useUserConfiguration } from '@dev/base-web/dist/view/components/global/current_user_context';
import { IntervalGroup } from '@/model/domain/report/manufacturing_entity_summary_interval.ts';
import { endOfDay, startOfDay } from 'date-fns';
import EmptyOrLoadingView from '@dev/base-web/dist/view/components/global/no_data';
import EventsListCardWithFilters from '@/components/events_list_with_filters';
import {
  ComponentType,
  MANUFACTURING_ENTITY_FILTER_KEY,
} from '@/components/events_list_with_filters/interface';
import { CURRENT_EVENTS_LIST_CARD_HEIGHT } from '@/components/events_list_with_filters/view';
import {
  FilterTag,
  FilterType,
} from '@dev/base-web/dist/view/components/filter_tags_and_sorting_dropdown/components/interfaces';
import { FilterOperation } from '@dev/base-web/dist/model/domain/common/filter_data';

const DASHBOARD_ITEMS: DashboardButtonProps[] = [
  {
    path: EVENTS_CONFIG.url.path,
    label: EVENTS_CONFIG.label ?? '',
    icon: 'dash-ereignisse',
    constraint: EVENTS_CONFIG.constraint,
  },
  {
    path: ACTIONS_CONFIG.url.path,
    label: ACTIONS_CONFIG.label ?? '',
    icon: 'dash-loesungswege',
    constraint: ACTIONS_CONFIG.constraint,
  },
  {
    path: REPORTS_CONFIG.url.path,
    label: REPORTS_CONFIG.label ?? '',
    icon: 'dash-ereignisse',
    constraint: REPORTS_CONFIG.constraint,
  },
  {
    path: USER_ADMIN_CONFIG.url.path,
    label: USER_ADMIN_CONFIG.label ?? '',
    icon: 'dash-nutzer',
    constraint: USER_ADMIN_CONFIG.constraint,
  },
];

const LeftColumn = styled(CardColumn)`
  min-width: 400px;

  --left-column-height: auto;

  @media screen and (${({ theme }) => theme.breakpoints.isDesktop}) {
    max-width: 400px;

    // 100px for the header, 24px for the top and bottom margins
    --left-column-height: calc(100vh - 100px - 2 * 24px);
    min-height: var(--left-column-height);
  }

  gap: 24px;
`;

const OpenDowntimeReportsContainer = styled.div`
  display: flex;
  flex: 1 0 auto;

  --gap-height: 12px;
  --current-events-card-height: ${CURRENT_EVENTS_LIST_CARD_HEIGHT}px;

  // 72 px for card title height and 24px for bottom margin
  min-height: calc(
    3 * ${OPEN_DOWNTIME_REPORT_LIST_ITEM_HEIGHT}px + 72px + 24px
  );

  // Only show 3 cards on mobile by default
  --open-downtime-reports-card-max-height: calc(
    3 * ${OPEN_DOWNTIME_REPORT_LIST_ITEM_HEIGHT}px + 72px + 24px
  );

  @media screen and (${({ theme }) => theme.breakpoints.isDesktop}) {
    --open-downtime-reports-card-default-height: calc(
      var(--left-column-height) - var(--gap-height) -
        var(--current-events-card-height)
    );
    --open-downtime-reports-card-4-items-height: calc(
      4 * ${OPEN_DOWNTIME_REPORT_LIST_ITEM_HEIGHT}px + 72px + 24px
    );

    // Show at most 4 cards on desktop
    --open-downtime-reports-card-max-height: min(
      var(--open-downtime-reports-card-default-height),
      var(--open-downtime-reports-card-4-items-height)
    );
  }

  max-height: var(--open-downtime-reports-card-max-height);
`;

const RightColumn = styled(FlexibleCardColumn)`
  display: flex;
  flex-direction: column;
`;

const KPIWrapper = styled.div`
  margin: 12px 12px 24px 48px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const DropdownContainer = styled.div`
  width: 100%;
  max-width: 200px;
  display: flex;
  justify-content: flex-end;
`;

const OptionContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  flex-shrink: 0;
  flex-grow: 9;
`;

const FilterPopupContainer = styled.div`
  padding-left: 12px;
  padding-right: 12px;
`;

const Dashboard = ({
  user,
  mainEntityKpis,
  configs,
  getAllMainEntityKpis,
  getUser,
  manufacturingEntities,
  getAllManufacturingEntities,
}: DashboardPropsInterface) => {
  const intl = useIntl();

  const [tourActive, setTourActive] = useState<boolean>(false);
  const [intervalType, setIntervalType] = useState<IntervalType>();
  const [considerPlannedStops, setConsiderPlannedStops] =
    useState<boolean>(false);
  const [eventListExternalFilterTag, setEventListExternalFilterTag] =
    useState<FilterTag>();

  const [userConfiguration, setUserConfiguration, ,] = useUserConfiguration();

  const showDowntimeReport = useAuthorizationCheck(false, {
    category: ApplicationCategory.SHANNON,
    permission: UserPrivilegePermission.REPORT,
  });

  const isSupportUser = user?.role.includes('SUPPORT');

  useEffect(() => {
    if (!manufacturingEntities || manufacturingEntities.length === 0)
      getAllManufacturingEntities();
  }, []);

  useEffect(() => {
    if (configs) {
      if (
        configs.dashboardStatsInterval &&
        (configs.dashboardStatsInterval.value ===
          IntervalType.PRODUCTION_SHIFT ||
          configs.dashboardStatsInterval.value === IntervalType.PRODUCED_ORDER)
      )
        setIntervalType(configs.dashboardStatsInterval.value);
      else setIntervalType(IntervalType.PRODUCTION_SHIFT);

      if (
        configs.considerPlannedProductionStops &&
        configs.considerPlannedProductionStops.value !== null
      )
        setConsiderPlannedStops(configs.considerPlannedProductionStops.value);
    }
  }, [configs]);

  useEffect(() => {
    if (intervalType && (!mainEntityKpis || mainEntityKpis.length == 0))
      getAllMainEntityKpis([], [intervalType]);
  }, [intervalType]);

  useEffect(() => {
    if (intervalType) {
      const timer = setInterval(() => {
        getAllMainEntityKpis([], [intervalType]);
        getUser();
      }, 30000);

      return () => {
        clearInterval(timer);
      };
    }
  }, [mainEntityKpis, intervalType]);

  const groupedMainEntityKpis = useMemo(() => {
    return groupKpisByMainEntity(mainEntityKpis);
  }, [mainEntityKpis]);

  useEffect(() => {
    if (
      groupedMainEntityKpis.length > 0 &&
      userConfiguration.selectedMainManufacturingEntity === undefined
    )
      setUserConfiguration({
        ...userConfiguration,
        selectedMainManufacturingEntity:
          groupedMainEntityKpis[0].manufacturingEntity,
      });
  }, [groupedMainEntityKpis]);

  const mainEntityOptions = useMemo(() => {
    return [
      { key: 'all', text: intl.formatMessage({ id: 'all' }), value: null },
      ...groupedMainEntityKpis.map((f) => ({
        key: f.manufacturingEntity.id,
        text: getManufacturingEntityTitle(f.manufacturingEntity, intl.locale),
        value: f.manufacturingEntity,
      })),
    ];
  }, [groupedMainEntityKpis, intl.locale]);

  const onMainEntityDropdownChanged = (
    _event: React.SyntheticEvent<HTMLElement>,
    data: DropdownProps
  ) => {
    let selectedEntity: ManufacturingEntity | null = null;
    if (data.value !== null) {
      selectedEntity = data.value as unknown as ManufacturingEntity;
    }

    setUserConfiguration({
      ...userConfiguration,
      selectedMainManufacturingEntity:
        selectedEntity as unknown as ManufacturingEntity,
    });

    if (selectedEntity !== null) {
      setEventListExternalFilterTag({
        type: FilterType.MANUFACTURING_ENTITY,
        icon: 'location',
        filterData: [
          {
            key: MANUFACTURING_ENTITY_FILTER_KEY,
            operation: FilterOperation.EQ_OPERATOR,
            value: [selectedEntity.id],
          },
        ],
        manufacturingEntities: manufacturingEntities,
      });
    } else {
      setEventListExternalFilterTag(undefined);
    }
  };

  const timespan: IntervalGroup = useMemo(() => {
    if (intervalType && user) {
      if (
        intervalType === IntervalType.PRODUCED_ORDER &&
        user.currentProductionOrder
      )
        return {
          description: '',
          type: 'PRODUCED_ORDER',
          name: '',
          start: user.currentProductionOrder.start,
          end: user.currentProductionOrder.end,
          plannedStopDuration: 0,
        };
      else if (
        intervalType === IntervalType.PRODUCTION_SHIFT &&
        user.currentProductionShift
      )
        return {
          description: '',
          type: 'PRODUCTION_SHIFT',
          name: '',
          start: user.currentProductionShift.start,
          end: user.currentProductionShift.end,
          plannedStopDuration: 0,
        };
    }

    if (userConfiguration.selectedMainManufacturingEntity) {
      const kpi = mainEntityKpis.find(
        (k) =>
          k.manufacturingEntity.id ===
          userConfiguration.selectedMainManufacturingEntity?.id
      );
      if (kpi) return kpi.interval;
    }
    return {
      description: '',
      start: startOfDay(new Date()).getTime(),
      end: endOfDay(new Date()).getTime(),
      name: '',
      type: 'DAY',
      plannedStopDuration: 0,
    };
  }, [
    intervalType,
    user,
    userConfiguration.selectedMainManufacturingEntity,
    mainEntityKpis,
  ]);

  return (
    <ScreenRoot>
      <div id="header">
        <Header title="Shannon Dashboard">
          <Tour
            page="dashboard"
            setTourActive={(active: boolean) => setTourActive(active)}
          />
        </Header>
      </div>
      <ScreenContent>
        <MultiCardLayout>
          {!isSupportUser && (
            <>
              <KPIWrapper>
                <IntervalKPIs
                  selectedEntity={
                    userConfiguration.selectedMainManufacturingEntity
                  }
                  kpis={groupedMainEntityKpis}
                  globalTimeSpan={timespan}
                />
                <OptionContainer>
                  <DropdownContainer>
                    <StyledDropDown
                      selection
                      fluid
                      value={userConfiguration.selectedMainManufacturingEntity}
                      options={mainEntityOptions}
                      onChange={onMainEntityDropdownChanged}
                      disabled={mainEntityOptions.length === 0}
                      placeholder={intl.formatMessage({ id: 'all' })}
                      search
                    />
                  </DropdownContainer>
                  <FilterPopupContainer>
                    <ChartOptionsPopup
                      user={user}
                      selectedIntervalType={intervalType}
                      setIntervalType={setIntervalType}
                      considerPlannedStops={considerPlannedStops}
                      setConsiderPlannedStops={setConsiderPlannedStops}
                    />
                  </FilterPopupContainer>
                </OptionContainer>
              </KPIWrapper>
              <CardRow>
                <LeftColumn>
                  <div id="curr_ev">
                    {userConfiguration.eventListFilterConfig ? (
                      <EventsListCardWithFilters
                        tourActive={tourActive}
                        componentType={ComponentType.dashboard}
                        externalFilterTag={eventListExternalFilterTag}
                      />
                    ) : (
                      <EmptyOrLoadingView loadingInProgress={true} />
                    )}
                  </div>
                  {showDowntimeReport && (
                    <OpenDowntimeReportsContainer>
                      <OpenDowntimeReportsListCard
                        selectedEntity={
                          userConfiguration.selectedMainManufacturingEntity
                        }
                      />
                    </OpenDowntimeReportsContainer>
                  )}
                </LeftColumn>
                <RightColumn>
                  <div id="downtimes">
                    <DowntimeCard
                      interval={timespan}
                      user={user}
                      considerPlannedStops={considerPlannedStops}
                      configs={configs}
                      selectedEntity={
                        userConfiguration.selectedMainManufacturingEntity
                      }
                    />
                  </div>
                  <div id="man_cap">
                    <DashBoardGrid buttons={DASHBOARD_ITEMS} />
                  </div>
                </RightColumn>
              </CardRow>
            </>
          )}
          {isSupportUser && (
            <SupportDashboard dashboardItems={DASHBOARD_ITEMS} />
          )}
        </MultiCardLayout>
      </ScreenContent>
    </ScreenRoot>
  );
};

export default Dashboard;
